import createDataContext from "./createDataContext"
import guardianCounselApi from "../apis/guardianCounselApi"
import { string } from "prop-types"

interface IAuthState {
  jwt: string
  message: string
}

interface IReducerAction {
  type: string
  payload: IAuthState
}

export const LocalStorageKeys = {
  jwt: "auth.jwt",
}

const authReducer = (state: IAuthState, action: IReducerAction) => {
  let newState = {}
  switch (action.type) {
    case "login":
      newState = { jwt: action.payload.jwt, isAuth: true, authData: parseJwt(action.payload.jwt) }
      break
    case "logout":
      newState = { jwt: null, isAuth: false, authData: {} }
      break
    default:
      console.log("Unhandled AuthReducer type: ", action.type)
      newState = state
      break
  }

  return newState
}

const parseJwt = (token: string) => {
  if (!token) {
    return null
  }

  var base64Url = token.split(".")[1]
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join("")
  )

  var jsonObj = JSON.parse(jsonPayload)

  return {
    name: jsonObj["unique_name"],
    email: jsonObj["email"],
    expiresAt: new Date(parseInt(jsonObj["exp"]) * 1000),
  }
}

const login = (dispatch: Function) => {
  return (authData: IAuthState) => {
    if (typeof window === `undefined`) {
      return false
    }

    dispatch({ type: "login", payload: authData })

    localStorage.setItem(LocalStorageKeys.jwt, authData.jwt)
  }
}

const logout = (dispatch: Function) => {
  return (message: string) => {
    if (typeof window === `undefined`) {
      return false
    }

    console.log("logout: ", message)

    dispatch({ type: "logout" })

    localStorage.removeItem(LocalStorageKeys.jwt)
  }
}

const checkSignedIn = (dispatch: Function) => {
  return async () => {
    if (typeof window === `undefined`) {
      return false
    }

    let jwt = localStorage.getItem(LocalStorageKeys.jwt)

    if (!jwt) {
      return false
    }

    try {
      await guardianCounselApi.get("/auth/whoami")

      dispatch({ type: "login", payload: { jwt: jwt } })

      return true
    } catch {
      localStorage.removeItem(LocalStorageKeys.jwt)

      dispatch({
        type: "logout",
        payload: { message: "You have been logged out because your previous session has expired." },
      })

      return false
    }
  }
}

export const { Provider, Context } = createDataContext(
  authReducer,
  { login, logout, checkSignedIn },
  { jwt: null, authData: null, isAuth: false }
)

export const AuthContext = Context
export const AuthProvider = Provider
